import { createStoreon } from 'storeon';
import uuid4 from 'uuid4';
import global from './global';
import Api from '../api/Api';
import testData from './testdata';
import SegmentUtil from '../util/SegmentUtil';
import { NetworkActivityTracker } from '../../utils/network/NetworkActivityTracker';
import { diContainer } from '../../inversify.config';
import { DI_TYPES } from '../../diTypes';
import * as mnmxIoCommon from '@conserv/mnmx-io-common';
import * as Sentry from '@sentry/react';

const nav = store => {
	store.on('@init', () => ({
		actions: {
			eventProfileCreated: false
		},
		customer: null,
		user: null,
		roles: [],
		subscription: null,
		notifications: [],
		navState: {
			pageTitle: 'Home',
			sensorView: 'card',
			selectedLocations: ['*'],
			sensorFilter: 'active',
			selectedSpace: '*'
		},
		observations: { scope: 'all', list: [], editing: null },
		locations: { list: [], activeList: [] },
		spaces: { list: [], activeList: [] },
		sensors: { list: [], activeList: [] },
		levels: { list: [] },
		eventProfiles: { list: null },
		users: [],
		preferences: null,
		reportTemplates: { list: null },
		kpiStats: {},
		analyticsState: {},
		savedViews: null,
		alerts: { list: null },
		importFiles: null,
		gateways: null,
		trapLocations: { list: [], activeList: [], types: [] },
		pests: { list: [], selected: {} },
		pestLifecycles: [],
		trapLocationReplace: {},
		loaded: new Set(),
		customerPests: [],
		pestCards: [],
		welcomeFlow: {
			step: 0,
			errorMessage: ''
		}
	}));

	store.on('customer/refresh', async state => {
		Api.getCustomer()
			.then(custRes => {
				Api.getCustomerModules()
					.then(moduleRes => {
						const customer = { ...custRes.data };
						customer.modules = moduleRes.data.map(mod => mod.module_name);
						store.dispatch('customer/updated', customer);
					})
					.catch(err => {
						store.dispatch('notification/add', {
							message: 'There was a problem loading customer modules',
							severity: 'error',
							variant: 'banner'
						});
					});
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem loading customer profile',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('customer/updated', ({ customer }, updatedCustomer) => ({
		customer: updatedCustomer
	}));
	store.on('customer/update', async (state, updatedCustomer) => {
		Api.updateCustomer({ customer: updatedCustomer })
			.then(updateRes => {
				store.dispatch('customer/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem updating your customer details',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('user/refresh', async (state, force) => {
		if (!state.user || force) {
			Api.getUser()
				.then(userRes => {
					if (userRes.data) {
						Sentry.setUser({
							id: userRes.data.uuid,
							email: userRes.data.email
						});
					}
					Api.getSubscription()
						.then(subRes => {
							SegmentUtil.identify(userRes.data, subRes.data);
							store.dispatch('user/updated', userRes.data);
							store.dispatch(
								'subscription/updated',
								subRes.data.find(sub => sub.active)
							);
						})
						.catch(err => {});
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem loading user profile',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});
	store.on('user/updated', ({ user }, updatedUser) => ({
		user: updatedUser
	}));
	store.on('user/updateprofile', async (state, updatedProfile) => {
		Api.updateProfile(updatedProfile)
			.then(updateRes => {
				store.dispatch('user/refresh', true);
				store.dispatch('welcomeFlow/update', {
					...state.welcomeFlow,
					step: state.welcomeFlow.step + 1
				});
			})
			.catch(err => {
				store.dispatch('welcomeFlow/update', {
					...state.welcomeFlow,
					errorMessage: 'There was a problem updating your user profile'
				});
				store.dispatch('notification/add', {
					message: 'There was a problem updating your user profile',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('subscription/refresh', async state => {
		Api.getSubscription()
			.then(subRes => {
				store.dispatch(
					'subscription/updated',
					subRes.data.find(sub => sub.active)
				);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem loading subscription details',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('subscription/updated', ({ subscription }, updatedSubscription) => ({
		subscription: updatedSubscription
	}));

	store.on('notification/add', ({ notifications, user }, details) => {
		// Check and see if this notification is already enqeued.  If so, don't duplicate it
		const duplicate = notifications.find(
			n =>
				n.message === details.message &&
				n.severity === details.severity &&
				n.variant === details.variant
		);
		if (!duplicate) {
			if (details.severity === 'error') {
				const error = details.error || new Error(details.message);
				Sentry.captureException(error, {
					user: {
						id: user?.uuid || '-1',
						email: user?.email || 'unknown'
					}
				});
			}

			return {
				notifications: [
					...notifications,
					{
						id: uuid4(),
						message: details.message,
						linkText: details.linkText,
						linkUrl: details.linkUrl,
						severity: details.severity ? details.severity : 'success',
						variant: details.variant ? details.variant : 'snackbar'
					}
				]
			};
		}
		return notifications;
	});
	store.on('notification/delete', ({ notifications }, id) => ({
		notifications: notifications.filter(notification => notification.id !== id)
	}));

	store.on('navstate/update', ({ navState }, newState) => {
		return { navState: { ...navState, ...newState } };
	});
	store.on('loaded/add', ({ loaded }, element) => {
		const newLoaded = new Set(loaded);
		newLoaded.add(element);
		return { loaded: newLoaded };
	});
	store.on('observations/refresh', async (state, params) => {
		const page = params?.page || 1;
		const pageSize =
			params?.pageSize ||
			mnmxIoCommon.commonConstant.PAGE_SIZE_EFFECTIVELY_ALL - 2;
		const scope = params?.scope ? params.scope : state.observations.scope;
		const range = params?.range ? params.range : {};
		const type = params?.type;
		const typeObs = params?.typeObs;
		// if we have a type specified, query by type.  Otherwise query by scope
		if (!type) {
			Api.getScopedObservations(scope, range, page, pageSize, typeObs)
				.then(obsRes => {
					const newobs = {
						list: obsRes.data.observations,
						scope,
						range,
						pages: obsRes.data.pages,
						total: obsRes.data.total
					};
					store.dispatch('observations/updated', newobs);
					store.dispatch('loaded/add', 'observations');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem loading the observation list',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			Api.getTypedObservations(type, range)
				.then(obsRes => {
					const newobs = { list: obsRes.data, scope, range };
					store.dispatch('observations/updated', newobs);
					store.dispatch('loaded/add', 'observations');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem loading the observation list',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});
	store.on('observations/delete', (state, params) => {
		Api.deleteObservation(params.deleteUuid)
			.then(res => {
				store.dispatch('notification/add', { message: 'Observation deleted' });
				store.dispatch('observations/refresh', params);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem deleting this observation',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('observations/updated', ({ observations }, updatedObservations) => ({
		observations: updatedObservations
	}));
	store.on('observations/edit', (state, edited) => ({
		observations: {
			editing: edited,
			list: state.observations.list,
			scope: state.observations.scope
		}
	}));
	store.on('observations/save', async (state, params) => {
		if (state.observations.editing.data.uuid) {
			Api.updateObservation(
				state.observations.editing.data.uuid,
				state.observations.editing
			)
				.then(updres => {
					if (state.observations.editing.newimage) {
						Api.updateObservationImage(
							state.observations.editing.uuid,
							state.observations.editing.newimage
						)
							.then(res => {
								store.dispatch('notification/add', { message: 'Image saved' });
							})
							.catch(err => {
								store.dispatch('notification/add', {
									message: 'There was an error saving your image',
									severity: 'error',
									variant: 'banner'
								});
							});
						store.dispatch('observations/refresh', params);
					} else {
						store.dispatch('notification/add', {
							message: 'Observation updated'
						});
						store.dispatch('observations/refresh', params);
						store.dispatch('navstate/update', {
							drawerOpen: false,
							drawerContent: null
						});
					}
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was an error updating this observation',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			Api.createObservation(state.observations.editing)
				.then(res => {
					const newobs = res.data;
					if (state.observations.editing.newimage) {
						Api.updateObservationImage(
							newobs.uuid,
							state.observations.editing.newimage
						)
							.then(res => {
								store.dispatch('notification/add', { message: 'Image saved' });
								store.dispatch('notification/add', {
									message: 'Observation created'
								});
								store.dispatch('observations/refresh', params);
								store.dispatch('navstate/update', {
									drawerOpen: false,
									drawerContent: null
								});
							})
							.catch(err => {
								store.dispatch('notification/add', {
									message: 'There was an saving your image',
									severity: 'error',
									variant: 'banner'
								});
							});
					} else {
						store.dispatch('notification/add', {
							message: 'Observation created'
						});
						store.dispatch('observations/refresh', params);
						store.dispatch('navstate/update', {
							drawerOpen: false,
							drawerContent: null
						});
					}
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was an error creating this observation',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});

	store.on('locations/refresh', async state => {
		Api.getFacilities()
			.then(facRes => {
				const facList = facRes.data;
				store.dispatch('locations/updated', facList);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem loading the facility list',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('locations/updated', ({ locations }, updatedLocations) => ({
		locations: {
			list: updatedLocations,
			activeList: updatedLocations.filter(loc => loc.active)
		}
	}));
	store.on('locations/edit', (state, edited) => ({
		locations: {
			editing: edited,
			list: state.locations.list,
			activeList: state.locations.activeList
		}
	}));
	store.on('locations/save', async state => {
		if (state.locations.editing.data.new === true) {
			Api.createFacility(state.locations.editing)
				.then(res => {
					store.dispatch('locations/refresh');
					store.dispatch('notification/add', { message: 'Location created' });
					store.dispatch('navstate/update', {
						drawerOpen: false,
						drawerContent: null
					});
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem updating this location',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			Api.updateFacility(state.locations.editing)
				.then(res => {
					store.dispatch('locations/refresh');
					store.dispatch('notification/add', { message: 'Location updated' });
					store.dispatch('navstate/update', {
						drawerOpen: false,
						drawerContent: null
					});
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem updating this location',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});
	store.on('locations/delete', async (state, locationid) => {
		Api.deleteFacility(locationid)
			.then(deleteRes => {
				store.dispatch('notification/add', { message: 'Location deleted' });
				store.dispatch('locations/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error deleting this location',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('sensors/refresh', async (state, force) => {
		if (force || state.sensors.list.length === 0) {
			Api.getAllCustomerSensors()
				.then(sensorRes => {
					const sensorList = sensorRes.data;
					store.dispatch('sensors/updated', sensorList);
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem loading the sensor list',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});
	store.on('sensors/updated', ({ sensors }, updatedSensors) => ({
		sensors: {
			list: updatedSensors,
			activeList: updatedSensors.filter(sen => sen.active)
		}
	}));
	store.on('sensors/edit', (state, edited) => ({
		sensors: {
			editing: edited,
			list: state.sensors.list,
			activeList: state.sensors.activeList
		}
	}));
	store.on('sensors/save', async state => {
		const apiCallback = state.sensors.editing.data.uuid
			? Api.updateSensor
			: Api.createSensor;

		apiCallback(state.sensors.editing)
			.then(sensorRes => {
				store.dispatch('notification/add', { message: 'Sensor updated' });
				store.dispatch('sensors/refresh', true);
				store.dispatch('navstate/update', {
					drawerOpen: false,
					drawerContent: null
				});
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem editing this sensor',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('sensors/sort', (state, sortOptions) => {
		// en = english; sensitivity: base = ignore capitalization on sort

		const sortFn = (a, b) => {
			let elOne;
			let elTwo;

			if (sortOptions.sortBy === 'name') {
				elOne = a.name;
				elTwo = b.name;
			} else if (sortOptions.sortBy === 'space') {
				elOne = a.zone.name;
				elTwo = b.zone.name;
			} else if (sortOptions.sortBy === 'location') {
				elOne = a.zone.facility.name;
				elTwo = b.zone.facility.name;
			}

			const result = elOne.localeCompare(elTwo, 'en', { sensitivity: 'base' });
			return sortOptions.sortAsc ? result : result * -1;
		};

		const sortedList = state.sensors.list.sort(sortFn);
		const sortedActiveList = state.sensors.activeList.sort(sortFn);

		return {
			sensors: { list: sortedList, activeList: sortedActiveList },
			sortOptions: { ...sortOptions }
		};
	});
	store.on('sensors/restore', async (state, sensor) => {
		Api.restoreSensor(sensor.id)
			.then(result => {
				store.dispatch('sensors/refresh', true);
				store.dispatch('notification/add', { message: 'Sensor restored' });
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem restoring this sensor',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('spaces/refresh', async state => {
		Api.getAllZones()
			.then(spaceRes => {
				const spaceList = spaceRes.data;
				store.dispatch('spaces/updated', spaceList);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem loading the space list',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('spaces/updated', ({ spaces }, updatedSpaces) => ({
		spaces: {
			list: updatedSpaces,
			activeList: updatedSpaces.filter(sp => sp.active)
		}
	}));
	// update the space we are editing
	store.on('spaces/edit', (state, edited) => ({
		spaces: {
			editing: edited,
			list: state.spaces.list,
			activeList: state.spaces.activeList
		}
	}));
	// save the space we are editing
	store.on('spaces/save', async state => {
		let savefn = null;
		if (!state?.spaces?.editing?.data?.id && !state?.spaces?.editing?.id) {
			savefn = Api.createFacilityZone;
		} else {
			savefn = Api.updateZone;
		}
		savefn(state.spaces.editing)
			.then(res => {
				store.dispatch('notification/add', { message: 'Space saved' });
				store.dispatch('navstate/update', {
					drawerOpen: false,
					drawerContent: null
				});
				store.dispatch('spaces/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error saving this space',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('spaces/delete', async (state, spaceid) => {
		Api.deleteZone(spaceid)
			.then(deleteRes => {
				store.dispatch('notification/add', { message: 'Space deleted' });
				store.dispatch('spaces/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error deleting this space',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('levels/refresh', async state => {
		Api.listLevelProfiles()
			.then(levelRes => {
				const levelList = levelRes.data;
				store.dispatch('levels/updated', levelList);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem loading the level profile list',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('levels/updated', ({ levels }, updatedLevels) => ({
		levels: { list: updatedLevels }
	}));
	store.on('levels/edit', (state, edited) => ({
		levels: { editing: edited, list: state.levels.list }
	}));
	store.on('levels/save', async state => {
		if (!state.levels.editing.seriesuuid) {
			Api.createLevelProfile(state.levels.editing)
				.then(newLevelRes => {
					store.dispatch('notification/add', {
						message: 'Level profile created'
					});
					store.dispatch('levels/refresh');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem creating a new level profile',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			Api.updateLevelProfile(
				state.levels.editing.seriesuuid,
				state.levels.editing
			)
				.then(updatedLevelRes => {
					store.dispatch('notification/add', {
						message: 'Level profile updated'
					});
					store.dispatch('levels/refresh');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was a problem updating the level profile',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});
	store.on('levels/descriptions/save', async state => {
		Api.updateLevelDescription(
			state.levels.editing.seriesuuid,
			state.levels.editing.descriptions
		)
			.then(res => {
				store.dispatch('notification/add', { message: 'Description saved' });
				store.dispatch('navstate/update', {
					drawerOpen: false,
					drawerContent: null
				});
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error saving level descriptions',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('levels/delete', async (state, seriesuuid) => {
		Api.deleteLevelProfile(seriesuuid)
			.then(deleteRes => {
				store.dispatch('notification/add', {
					message: 'Level profile deleted'
				});
				store.dispatch('levels/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error deleting a level profile',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('eventprofiles/refresh', async state => {
		Api.listEventProfiles()
			.then(eventDefs => {
				store.dispatch('eventprofiles/updated', eventDefs.data);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading the event profiles',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on(
		'eventprofiles/updated',
		({ eventProfiles }, updatedEventProfiles) => ({
			eventProfiles: { list: updatedEventProfiles }
		})
	);
	store.on('eventprofiles/edit', ({ eventProfiles }, newDef) => {
		return { eventProfiles: { list: eventProfiles.list, editing: newDef } };
	});
	store.on('actions/eventprofiles/created', () => {
		return { actions: { eventProfileCreated: true } };
	});
	store.on('actions/eventprofiles/removecreated', () => {
		return { actions: { eventProfileCreated: false } };
	});
	store.on('eventprofiles/save', async ({ eventProfiles }) => {
		if (eventProfiles.editing.uuid) {
			Api.updateEventProfile(eventProfiles.editing.uuid, eventProfiles.editing)
				.then(updated => {
					SegmentUtil.track(SegmentUtil.actions.saveeventprofile);
					store.dispatch('eventprofiles/refresh');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was an error updating this event profile',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			Api.createEventProfile(eventProfiles.editing)
				.then(created => {
					store.dispatch('actions/eventprofiles/created');
					// automatically subscribe the creator of an event to the event they just created?
					store.dispatch('eventprofiles/refresh');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was an error creating this event profile',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});

	store.on('users/refresh', async state => {
		Api.getCustomerUsers()
			.then(userRes => {
				function removeDuplicates(arr) {
					const unique = arr.reduce(function (acc, currUsr) {
						if (!acc.some(usr => currUsr.mnmxuserId === usr.mnmxuserId))
							acc.push(currUsr);
						return acc;
					}, []);
					return unique;
				}

				const { users, customerusers } = userRes.data;
				const uniqueCustomerUsers = removeDuplicates(customerusers); //remove this when duplicate issue is fixed
				const userList = users.concat(
					uniqueCustomerUsers
						.filter(
							customeruser =>
								!users.some(usr => usr.id === customeruser.mnmxuserId)
						)
						.map(cusUsr => ({
							...cusUsr.mnmxuser,
							role: cusUsr.customerrole
						}))
				);
				store.dispatch('users/updated', userList);
				store.dispatch('loaded/add', 'users');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading the user list',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('users/updated', ({ users }, updatedUsers) => ({
		users: updatedUsers
	}));

	store.on('preferences/refresh', async (state, force) => {
		if (force || !state.preferences) {
			Api.getPreferences()
				.then(prefRes => {
					store.dispatch('preferences/updated', prefRes.data);
					store.dispatch('loaded/add', 'preferences');
				})
				.catch(err => {
					store.dispatch('notification/add', {
						message: 'There was an error loading your preferences',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	});
	store.on('preferences/updated', ({ preferences }, updatedPrefs) => ({
		preferences: updatedPrefs
	}));

	store.on('reportTemplates/refresh', ({ reportTemplates }) => ({
		reportTemplates: { list: testData.reportTemplates }
	}));

	store.on('kpiStats/all/refresh', async (state, params) => {
		const kpiPromises = [];
		const kpiData = {};

		const damageRiskProviderService = diContainer.get(
			DI_TYPES.DamageRiskProviderService
		);

		// Treat getDamageRisk slightly different than the others
		// Read more below
		kpiPromises.push(Api.getDamageRisk(params.sensorUuid, params.range));

		kpiPromises.push(
			Api.getAllLevelCompliance(params.sensorUuid, params.range)
		);
		kpiPromises.push(Api.getMoldRisk(params.sensorUuid, params.range));

		// In the short term, treat the damagerate request slightly differently
		// i.e.:  Do NOT show a toast error if the /damagerate request times out
		// See https://linear.app/conserv1/issue/PRO-1397/analytics-page-there-was-an-error-loading-kpi-data-when-this-happens
		//
		// kpiPromises.push(Api.getDamageRisk(params.sensorUuid, params.range));

		kpiPromises.push(
			Api.getCumulativeLightHours(params.sensorUuid, params.range)
		);
		kpiPromises.push(Api.getScore(params.sensorUuid, params.range));
		Promise.all(kpiPromises)
			.then(results => {
				NetworkActivityTracker.pingGood();
				results.forEach(result => {
					kpiData[result.data.name] = result.data.data;
				});
				store.dispatch('kpiStats/updated', kpiData);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading kpi data',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('kpiStats/updated', ({ kpis }, updatedKpis) => ({
		kpiStats: updatedKpis
	}));

	store.on(
		'analyticsState/updated',
		({ analyticsState }, updatedAnalyticsState) => ({
			analyticsState: updatedAnalyticsState
		})
	);

	store.on('savedViews/refresh', async (state, params) => {
		Api.getSavedGraphs('savedview')
			.then(viewResult => {
				store.dispatch('savedViews/updated', viewResult.data);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading your saved graph views',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('savedViews/updated', ({ views }, updatedViews) => ({
		savedViews: updatedViews
	}));

	store.on('importFiles/refresh', async state => {
		Api.listImports()
			.then(importFileResult => {
				store.dispatch('importFiles/updated', importFileResult.data);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading the list of previous imports',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('importFiles/updated', ({ importFiles }, updatedImportFiles) => ({
		importFiles: updatedImportFiles
	}));

	store.on('invites/send', async (state, invitations) => {
		Api.inviteUsers(invitations)
			.then(updateRes => {
				store.dispatch('welcomeFlow/update', {
					...state.welcomeFlow,
					step: state.welcomeFlow.step + 1
				});
			})
			.catch(err => {
				store.dispatch('welcomeFlow/update', {
					...state.welcomeFlow,
					errorMessage: 'We couldn’t send invitation.'
				});
			});
	});

	store.on('gateways/refresh', async state => {
		Api.getCustomerGateways()
			.then(gatewayResult => {
				store.dispatch('gateways/updated', gatewayResult.data);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem loading the list of gateways, retrying',
					severity: 'warning',
					variant: 'banner'
				});
			});
	});
	store.on('gateways/updated', ({ gateways }, updatedGateways) => ({
		gateways: updatedGateways
	}));
	store.on('gateway/edit', (state, edited) => ({
		gateway: { editing: edited, list: state.gateways }
	}));
	store.on('gateway/save', async state => {
		Api.updateGateway(state.gateway.editing)
			.then(gatewayRes => {
				store.dispatch('notification/add', { message: 'Gateway updated' });
				store.dispatch('gateways/refresh', true);
				store.dispatch('navstate/update', {
					drawerOpen: false,
					drawerContent: null
				});
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was a problem editing this gateway',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('trapLocations/refresh', async state => {
		Api.listTraplocations()
			.then(trapLocRes => {
				const sorted = trapLocRes.data.sort((a, b) =>
					a.name.localeCompare(b.name, 'en', { numeric: true })
				);
				store.dispatch('trapLocations/updated', sorted);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading the list of pest monitors',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on(
		'trapLocations/updated',
		({ trapLocations }, updatedTrapLocations) => ({
			trapLocations: {
				list: updatedTrapLocations,
				activeList: updatedTrapLocations.filter(
					loc => !Boolean(loc.deleted_at)
				),
				types: (() => {
					const types = updatedTrapLocations.map(loc => loc.type);
					const allTypes = types.concat(global.defaultTrapTypes);
					return Array.from(new Set(allTypes)).filter(type => Boolean(type));
				})()
			}
		})
	);
	store.on('trapLocations/edit', (state, edited) => ({
		trapLocations: {
			editing: edited,
			list: state.trapLocations.list,
			activeList: state.trapLocations.activeList,
			types: state.trapLocations.types
		}
	}));

	store.on('trapLocations/save', async state => {
		let saveFn;
		if (state.trapLocations.editing.data.uuid) {
			saveFn = Api.updateTrapLocation;
		} else {
			saveFn = Api.createTrapLocation;
		}

		saveFn(state.trapLocations.editing)
			.then(trapLocRes => {
				store.dispatch('notification/add', { message: 'Pest Monitor saved' });
				store.dispatch('trapLocations/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error saving this pest monitor',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('trapLocationReplace/save', async state => {
		Api.createTrapLocationReplace(
			state.trapLocations.editing.uuid,
			state.trapLocations.editing.trapType,
			state.trapLocations.editing.lastReplaced,
			state.trapLocations.editing.note
		)
			.then(trapLocRes => {
				store.dispatch('notification/add', {
					message: 'Pest monitor replacement saved'
				});
				store.dispatch('trapLocations/refresh');
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error saving this pest monitor replacement',
					severity: 'error',
					variant: 'banner'
				});
			});
	});

	store.on('pests/refresh', async state => {
		Api.getPestList()
			.then(pestResult => {
				const sorted = pestResult.data.sort((a, b) =>
					a.common_name > b.common_name ? 1 : -1
				);
				store.dispatch('pests/updated', sorted);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading the pest list',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('pests/updated', ({ pests }, updatedPests) => ({
		pests: { list: updatedPests, selected: pests.selected }
	}));
	store.on('pests/select', ({ pests }, selected) => ({
		pests: { list: pests.list, selected }
	}));

	store.on('customerPests/refresh', async state => {
		Api.getCustomerPestList()
			.then(pestResult => {
				store.dispatch('customerPests/updated', pestResult.data);
			})
			.catch(err => {
				store.dispatch('notification/add', {
					message: 'There was an error loading the customer pest list',
					severity: 'error',
					variant: 'banner'
				});
			});
	});
	store.on('customerPests/updated', ({ customerPests }, updatedPests) => ({
		customerPests: updatedPests
	}));

	store.on('pestLifecycles/refresh', async state => {
		Api.getPestLifecycles().then(lifecycleResult => {
			const sortedLifecycles = lifecycleResult.data.sort((a, b) =>
				a.name.localeCompare(b.name, 'en', { numeric: true })
			);
			store.dispatch('pestLifecycles/updated', sortedLifecycles);
		});
	});
	store.on(
		'pestLifecycles/updated',
		({ pestLifecycles }, updatedLifecycles) => ({
			pestLifecycles: updatedLifecycles
		})
	);

	store.on('pestCards/set', ({ pestCards }, newCards) => ({
		pestCards: newCards
	}));

	store.on('roles/set', ({ roles }, newRoles) => ({ roles: newRoles }));

	store.on('welcomeFlow/update', ({ welcomeFlow }, newWelcomeFlow) => ({
		welcomeFlow: newWelcomeFlow
	}));
};

const store = createStoreon([
	nav
	// Store state in localStorage
	// persistState(['alerts']),
]);

export default store;
